@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .select {
    @apply px-1 py-2 mr-3 outline-none bg-gray-300 rounded-sm hover:bg-gray-400;
  }

  .option {
    @apply bg-gray-200 outline-none my-6 border-none py-3;
  }
}

html,
body {
  overscroll-behavior: none;

}


#spdiv {
  flex-direction: row;
  margin: 0px 5px;

}

#slogo {
  flex-direction: row;
  margin: 0px 5px;

  height: 15px;

}

#seelaydiv {
  align-items: center;
  display: flex;

}

#arcb {
  display: flex;
  flex-direction: column-reverse;
  min-height: 500px;
  /* Reserve space for content */
  padding: 2.5rem 1rem;
  box-sizing: border-box;

}

@media (min-width: 768px) {
  #arcb {
    flex-direction: row;
    justify-content: space-between;
    padding: 2.5rem 5rem;
  }
}

#arcb>div {
  flex: 1;
  min-width: 300px;
  /* Prevent content collapse */
}

#arcb img,
#arcb svg {
  max-width: 100%;
  height: auto;
}

.plan-placeholder {
  width: 400px;
  /* Adjust based on content */
  height: 300px;
  background-color: #f0f0f0;
}