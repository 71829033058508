/* @font-face {
  font-family: "Circular";
  src: url("./Circular.woff2");
  font-display: swap;
} */

@font-face {
  font-family: "Aquire";
  src: url("./Aquire.woff2");
  font-display: swap;
  font-style: normal;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif, "Aquire";
  overflow: overlay;
  touch-action: pan-x pan-y;
  font-style: normal;

}

progress {
  height: 4px;
  border-radius: 3px;
}

progress::-webkit-progress-bar {
  background-color: rgba(0, 0, 0, 0.18);
  border-radius: 7px;
}

progress::-webkit-progress-value {
  background-color: #2d69e8;
  border-radius: 2px;
}

progress::-moz-progress-bar {
  /* style rules */
}

/* custom scrollbar */

*::-webkit-scrollbar {
  height: 7px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 7px;
  /* width of vertical scrollbar */
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/* custom components scrollbar */

.grid::-webkit-scrollbar {
  height: 10px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 10px;
  /* width of vertical scrollbar */
}

@media only screen and (max-width: 600px) {
  .grid::-webkit-scrollbar {
    height: 7px;
    /* height of horizontal scrollbar ← You're missing this */
    width: 7px;
    /* width of vertical scrollbar */
  }
}

[data-reach-dialog-overlay]::-webkit-scrollbar-thumb {
  background-color: #eeeeee;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

[data-reach-dialog-overlay]::-webkit-scrollbar-thumb:hover {
  background-color: #d6dee1;
}

/* custom components scrollbar */

/* custom scrollbar */

/* Reach UI Modal */

:root {
  --reach-dialog: 1;
}

.arch {
  font-family: "Aquire";
  font-weight: 600;
}

[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 40;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.fade-in {
  animation: fade-in 0.2s ease-out;
}

[data-reach-dialog-content] {
  width: 85vw;
  margin: 10vh auto;
  background: white;
  padding: 1.5rem;
  outline: none;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.spinner {
  color: #1d4ed8;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  margin-top: 200px;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.brd {
  background: rgb(238, 242, 255);
  background: -moz-linear-gradient(135deg,
      rgba(238, 242, 255, 1) 0%,
      rgba(239, 246, 255, 1) 35%,
      rgba(254, 242, 242, 1) 100%);
  background: -webkit-linear-gradient(135deg,
      rgba(238, 242, 255, 1) 0%,
      rgba(239, 246, 255, 1) 35%,
      rgba(254, 242, 242, 1) 100%);
  background: linear-gradient(135deg,
      rgba(238, 242, 255, 1) 0%,
      rgba(239, 246, 255, 1) 35%,
      rgba(254, 242, 242, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eef2ff", endColorstr="#fef2f2", GradientType=1);
}